import React, { FC, memo } from "react";
import { FirebaseContext } from "../../context/Firebase";
import { useContext } from "react";
import Container from "./Container";
import { Link } from "react-router-dom";
import { RxCross2 } from "react-icons/rx";
const BlogsMainNavlist: FC<{ isVisible: boolean; toggleVisibility: any }> =
  memo(({ isVisible, toggleVisibility }) => {
    const { logoutUser } = useContext(FirebaseContext);

    return (
      <div>
        <div
          className={`absolute ${
            isVisible ? "top-0 right-0 h-screen " : "right-[-100%]"
          } z-50 w-[350px] block bg-white`}
        >
          <Container>
            <>
              <div className="flex">
                <button
                  className="text-black font-bold text-[40px]  flex ml-auto cursor-pointer px-3 pt-[30px]"
                  type="button"
                  onClick={toggleVisibility}
                >
                  <RxCross2 />
                </button>
              </div>
              <div className=" p-16 ">
                <ul className=" font-Montserrat text-black space-y-6 text-xl font-medium flex flex-col ">
                  <li>
                    <Link to="/admin">Dashboard</Link>
                  </li>
                  <li>
                    <Link to="/admin/blogs">Blogs</Link>
                  </li>
                  <li>
                    <Link to="/admin/clients">Client</Link>
                  </li>
                  <li>
                    <button
                      onClick={() => logoutUser()}
                      className=" bg-green-600 hover:bg-red-600 p-3 rounded-lg text-lg font-bold text-slate-100"
                    >
                      Log Out
                    </button>
                  </li>
                </ul>
              </div>
            </>
          </Container>
        </div>
      </div>
    );
  });

export default BlogsMainNavlist;
