import React, { FC, memo } from "react";
import Container from "./Container";
import { RxCross2 } from "react-icons/rx";
const MobileNavlist: FC<{ isVisible: boolean; toggleVisibility: any,dark:boolean }> = memo(
  ({ isVisible, toggleVisibility,dark }) => {
    return (
      <div>
        <div
          className={`absolute ${
            isVisible ? "top-0 right-0 h-screen " : "right-[-100%]"
          } z-50 w-[350px] block lg:hidden bg-primary`}
        >
          <Container>
            <>
              <div className="flex">
                <button
                  className="text-white font-bold text-[40px]  flex ml-auto cursor-pointer px-3 pt-[30px]"
                  type="button"
                  onClick={toggleVisibility}
                >
                  <RxCross2 />
                </button>
              </div>
              <div className=" p-16 ">
                <ul className=" font-Montserrat text-white space-y-6 text-2xl font-normal flex flex-col ">
                {
                  dark?<>
                  <li>
                    <a href="/">Home</a>
                  </li>
                  <li>
                    <a href="/">Blogs</a>
                  </li>
                  </>:<>
                    <li>
                    <a href="#service">Services</a>
                  </li>
                  <li>
                    <a href="#offer">What We Offer</a>
                  </li>
                  <li>
                    <a href="#blog">Blogs</a>
                  </li>
                  <li>
                    <a href="#contact">Contact Us</a>
                  </li>
                  </>
                }
                
                </ul>
              </div>
            </>
          </Container>
        </div>
      </div>
    );
  }
);

export default MobileNavlist;
