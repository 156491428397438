import React from "react";
import { Link } from "react-router-dom";

const Admin = () => {
  return (
    <div>
      <div className="flex ">
        <Link to="blogs" className="bg-white shadow-xl rounded-xl w-1/4 p-4 hover:shadow-none">
          <h1 className="text-3xl font-bold">Blogs</h1>
          <div className="text-[100px] leading-[80px] text-right w-full">5</div>
        </Link>
      </div>
    </div>
  );
};

export default Admin;
