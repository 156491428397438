import React, { FC, memo } from "react";

const ClientInfoCard: FC<{
  firstname: string;
  lastname:string
  email?: string;
  phone: string;
  message?: string;
  date?:string;
}> = memo(({ firstname, lastname, email, phone, message,date }) => {
  return (
    <tbody className="divide-y divide-gray-200 bg-gray-100">
      <tr>
        <td className="px-6 py-4 text-sm text-gray-800 whitespace-nowrap">
          {firstname}
        </td>
        {/* <td className="px-6 py-4 text-sm text-gray-800 whitespace-nowrap">
          {lastname}
        </td> */}
        <td className="px-6 py-4 text-sm text-gray-800 whitespace-nowrap">
          {email}
        </td>
        <td className="px-6 py-4 text-sm text-gray-800 whitespace-nowrap">
          {phone}
        </td>
        <td className="px-6 py-4 text-sm text-gray-800 whitespace-nowrap">
          {message}
        </td>
        <td className="px-6 py-4 text-sm text-gray-800 whitespace-nowrap">
          {date}
        </td>
      </tr>
    </tbody>
  );
});

export default ClientInfoCard;
