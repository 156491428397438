import actions from "./Actions";
const {GET_PROJECTS_DATA,GET_Info_DATA,GET_Blog_DATA,GET_SINGLE_Blog_DATA,SIGN_IN_INFO,SAVE_IN_INFO,SAVE_IN_BLOG,GET_Service_DATA,GET_Gallery_DATA } = actions

const reducer = (state: any, actions: { payload: any, type: string }) => {
    switch (actions.type) {
        case SIGN_IN_INFO:
        return { ...state, userInfo: actions.payload };
        case SAVE_IN_INFO:
        return { ...state, SaveInfo: actions.payload };
        case SAVE_IN_BLOG:
        return { ...state, SaveBlog: actions.payload };
        case GET_Info_DATA:
        return { ...state, InfoData: actions.payload };
        case GET_Blog_DATA:
        return { ...state, BlogData: actions.payload };
        case GET_SINGLE_Blog_DATA:
        return { ...state, sinleBlogData: actions.payload };
        case GET_PROJECTS_DATA:
        return { ...state, projectsData: actions.payload };
        case GET_Service_DATA:
        return { ...state, serviceData: actions.payload };
        case GET_Gallery_DATA:
        return { ...state, galleryData: actions.payload };
        default:
        return state
    }
}

export default reducer