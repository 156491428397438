import { createContext, useContext, useReducer } from "react";
import { initializeApp } from "firebase/app";
import {
  getFirestore,
  getDocs,
  collection,
  addDoc,
} from "firebase/firestore/lite";
import StoreReducer from "./StoreReducer";

import actions from "./Actions";
import { initialFirebaseContext } from "../Confige/Types";
import {
  getAuth,
  onAuthStateChanged,
  signInWithEmailAndPassword,
  signOut,
} from "firebase/auth";
import Swal from "sweetalert2";
const {
  GET_PROJECTS_DATA,
  GET_Info_DATA,
  GET_Blog_DATA,
  SIGN_IN_INFO,
  SAVE_IN_INFO,
  SAVE_IN_BLOG,
  GET_Service_DATA,
  GET_Gallery_DATA,
  GET_SINGLE_Blog_DATA
} = actions;
//firebase project link
const firebaseConfig = {
  apiKey: "AIzaSyACJYKd9neSNfiBDfDdlBCPBoRTa6XFeyg",
  authDomain: "tech-nebula-portfolio.firebaseapp.com",
  projectId: "tech-nebula-portfolio",
  storageBucket: "tech-nebula-portfolio.appspot.com",
  messagingSenderId: "793685466835",
  appId: "1:793685466835:web:03300a22c22bce06c33e26",
  measurementId: "G-XH97KZS1CG"
};

const firebaseApp = initializeApp(firebaseConfig);
export const useFirebase = () => useContext(FirebaseContext);
export const auth = getAuth(firebaseApp);
import blogsJson from "../assets/json/blogs";
const initialContext: initialFirebaseContext = {
  projectsData: [],
  InfoData: [],
  BlogData: [],
  serviceData: [],
  galleryData: [],
  sinleBlogData:undefined,
  userInfo: JSON.parse(localStorage.getItem("userInfo") || "null"),
  getInfoData: () => {},
  getProjectData: () => {},
  getBlogData: () => {},
  signinUser: () => {},
  SaveInfo: (data: any) => {},
  SaveBlog: (data: any) => {},
  getAuthInfo: () => {},
  logoutUser: () => {},
  getServiceData: () => {},
  getGalleryData: () => {},
  getSingleBlogData: (slug:string) => {},
};
export const FirebaseContext = createContext(initialContext);

export const FirebaseProvider = (props: any) => {
  const [state, dispatch] = useReducer(StoreReducer, initialContext);
  //Page Validation for user authentication
  const getAuthInfo = () => {
    const data = onAuthStateChanged(auth, (user: any) => {
      if (user) {
        return user;
      } else {
        // User is logged out
        return null;
      }
    });
    return data;
  };

  //Log out user *
  const logoutUser = () => {
    signOut(auth);
    dispatch({ payload: null, type: SIGN_IN_INFO });
    localStorage.setItem("userInfo", JSON.stringify(""));
  };

  //Sign In User *
  const signinUser = (email: string, password: string) => {
    signInWithEmailAndPassword(auth, email, password)
      .then((value) => {
        dispatch({ payload: value, type: SIGN_IN_INFO });
        localStorage.setItem("userInfo", JSON.stringify(value));
        Swal.fire({
          position: "center",
          icon: "success",
          title: "Valid Email",
          showConfirmButton: false,
          timer: 1000,
        });
      })
      .catch((err) => {
        Swal.fire({
          position: "center",
          icon: "error",
          title: "In-Valid Email",
          showConfirmButton: true,
        });
      });
  };

  //Save Info Data *
  const SaveInfo = (data: any) => {
    try {
      const userCollectionRef = collection(db, "contactdata");
      return addDoc(userCollectionRef, data)
        .then((value) => {
          dispatch({ payload: value, type: SAVE_IN_INFO });
          Swal.fire("Good job!", "Your data has been submitted!", "success");
          return true;
        })
        .catch((error) => {
          alert(error?.message);
          return false;
        });
    } catch (error) {
      console.log("err", error);
    }
  };

  //Save Blog Data *
  const SaveBlog = (data: any) => {
    try {
      const userCollectionRef = collection(db, "blogs");
      return addDoc(userCollectionRef, data)
        .then((value) => {
          dispatch({ payload: value, type: SAVE_IN_BLOG });
          Swal.fire("Good job!", "Your data has been submitted!", "success");
          return true;
        })
        .catch((error) => {
          alert(error?.message);
          return false;
        });
    } catch (error) {
      console.log("err", error);
    }
  };

  //Get Client Info Data *
  const getInfoData = async () => {
    try {
      const Infodata = await getDocs(
        collection(getFirestore(firebaseApp), "contactdata")
      );
      const InfonewData: any = [];
      Infodata.forEach((obj) => InfonewData.push(obj.data()));
      dispatch({
        payload: InfonewData,
        type: GET_Info_DATA,
      });
    } catch (error) {
      console.log("err", error);
    }
  };

  //Get Blog Info Data *
  const getBlogData = async () => {
    try {
      dispatch({
        payload: blogsJson,
        type: GET_Blog_DATA,
      });
    } catch (error) {
      console.log("err", error);
    }
  };
  //Get Blog Info Data *
  const getSingleBlogData = async (slug:string) => {
    try {
      dispatch({
        payload: blogsJson.find(obj=>obj.slug===slug),
        type: GET_SINGLE_Blog_DATA,
      });
    } catch (error) {
      console.log("err", error);
    }
  };

  //Get Services data *
  const getServiceData = async () => {
    try {
      const servicedata = await getDocs(
        collection(getFirestore(firebaseApp), "Services")
      );
      const servicenewData: any = [];
      servicedata.forEach((obj) => servicenewData.push(obj.data()));

      dispatch({
        payload: servicenewData,
        type: GET_Service_DATA,
      });
    } catch (error) {
      console.log("err", error);
    }
  };

//Projects data
const getProjectData = async () => {
  const data = await getDocs(
    collection(getFirestore(firebaseApp), "projects")
  );
  const newData: any = [];
  data.forEach((obj) => newData.push(obj.data()));

  dispatch({
    payload: newData,
    type: GET_PROJECTS_DATA,
  });
};

  //Gallery
  const getGalleryData = async () => {
    try {
      const gallerydata = await getDocs(
        collection(getFirestore(firebaseApp), "gallery")
      );
      const gallerynewData: any = [];
      gallerydata.forEach((obj) => gallerynewData.push(obj.data()));

      dispatch({
        payload: gallerynewData,
        type: GET_Gallery_DATA,
      });
    } catch (error) {
      console.log("err", error);
    }
  };

  return (
    <FirebaseContext.Provider
      value={{
        ...state,
        getProjectData,
        getInfoData,
        getBlogData,
        getAuthInfo,
        signinUser,
        SaveInfo,
        SaveBlog,
        logoutUser,
        getServiceData,
        getGalleryData,
        getSingleBlogData
      }}
    >
      {props.children}
    </FirebaseContext.Provider>
  );
};
//previous project method
export const db = getFirestore(firebaseApp);
export const app = initializeApp(firebaseConfig);
