import React, { FC, memo } from "react";
import { Link } from "react-router-dom";
import { BsLinkedin } from "react-icons/bs";
const Navlist: FC<{dark:boolean}> = memo(({dark}) => {
  return (
    <>
      <nav className="hidden md:block relative">
        <ul className="text-base flex space-x-7 flex-col md:flex-row justify-center md:items-center">
         {
          dark?<>
          <li className="py-2">
            <a href="/">Home</a>
          </li>
          <li className="py-2">
            <a href="/">Blogs</a>
          </li>
          </>:<>
          <li className="py-2">
            <a href="#service">Services</a>
          </li>
          <li className="py-2">
            <a href="#blog">Blogs</a>
          </li>
          <li className="py-2">
            <a href="#contactus">Contact us</a>
          </li>
          <li className="py-2">
            <a href="#aboutus">About us</a>
          </li>
          </>
         }
          
          <ul className=" flex space-x-3">
            <li>
              <Link to="https://www.linkedin.com/company/tech-nebula/">
                <BsLinkedin />
              </Link>
            </li>
          </ul>
        </ul>
      </nav>
    </>
  );
});

export default Navlist;
