import React, { memo, useState } from "react";
import ClientInfoCard from "./utilities/ClientInfoCard";
import Container from "./utilities/Container";
import { useContext, useEffect } from "react";
import { FirebaseContext } from "../context/Firebase";
import { InfoProps } from "../Confige/Types";
import BlogsMainNavlist from "./utilities/BlogsMainNavlist";
import { Link } from "react-router-dom";
import logo from "../assets/svg/logog.svg";
import Image from "../components/utilities/Image";
import { BiMenu } from "react-icons/bi";
const ClientInfo = memo(() => {
  const { InfoData, getInfoData } = useContext(FirebaseContext);
  useEffect(() => {
    getInfoData();
  }, []);
  const [isVisible, setIsVisible] = useState(false);

  const toggleVisibility = () => {
    setIsVisible(!isVisible);
  };
  return (
    <div className="min-h-screen w-full">
      <div className=" bg-white">
        <Container>
          <div className="flex py-4 justify-between md:items-center">
            <div>
              <Link to="/">
                <Image src={logo} alt="Tech Nebula" className="h-12 " />
              </Link>
            </div>
            <div className="flex items-center justify-center">
              <button
                className=" text-black flex ml-auto  z-10 cursor-pointer text-3xl border border-solid border-transparent rounded bg-transparent outline-none focus:outline-none"
                type="button"
                onClick={toggleVisibility}
              >
                <BiMenu />
              </button>
            </div>
          </div>
        </Container>
        <BlogsMainNavlist
          isVisible={isVisible}
          toggleVisibility={toggleVisibility}
        />
      </div>
      <div className="bg-[#F7F7F7] min-h-screen">
        <Container>
          <>
            <div className="pb-6">
              <h2 className="text-[#214B4D] text-3xl sm:text-4xl md:text-5xl font-bold text-center ">
                Client Data
              </h2>
            </div>
            <div className="flex flex-col">
              <div className="overflow-x-auto">
                <div className="p-1.5 w-full inline-block align-middle">
                  <div className="w-full border rounded-lg">
                    <table className="w-full divide-y divide-gray-200">
                      <thead className="bg-gray-50">
                        <tr>
                          <th
                            scope="col"
                            className="px-6 py-3 text-xs font-bold text-left text-gray-500 uppercase "
                          >
                            Name
                          </th>
                          <th
                            scope="col"
                            className="px-6 py-3 text-xs font-bold text-left text-gray-500 uppercase "
                          >
                            Email
                          </th>
                          <th
                            scope="col"
                            className="px-6 py-3 text-xs font-bold text-left text-gray-500 uppercase "
                          >
                            Contact No.
                          </th>
                          <th
                            scope="col"
                            className="px-6 py-3 text-xs font-bold text-center text-gray-500 uppercase "
                          >
                            Message
                          </th>
                          <th
                            scope="col"
                            className="px-6 py-3 text-xs font-bold text-center text-gray-500 uppercase "
                          >
                            date
                          </th>
                        </tr>
                      </thead>
                      {(InfoData || []).map((obj: InfoProps) => (
                        <ClientInfoCard
                          key={obj.id}
                          firstname={obj.firstname}
                          lastname={obj.lastname}
                          email={obj.email}
                          phone={obj.phone}
                          message={obj.message}
                          date={obj.date}
                        />
                      ))}
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </>
        </Container>
      </div>
    </div>
  );
});

export default ClientInfo;
