import { FC, memo, useEffect, useState } from "react";
import Image from "./utilities/Image";
import logo from "../assets/svg/logo.svg";
import Container from "./utilities/Container";
import Navlist from "./Navlist";
import { Link } from "react-router-dom";
import { BiMenu } from "react-icons/bi";
import MobileNavlist from "./utilities/MobileNavlist";
const Navbar: FC<{ dark: boolean }> = memo(({ dark = false }) => {
  const [navChanger, setnavChanger] = useState(false);
  const changeNavColor = (data: any) => {
    if (window.scrollY > window.innerHeight) {
      setnavChanger(true);
    } else {
      setnavChanger(false);
    }
  };
  useEffect(() => {
    window.addEventListener("scroll", changeNavColor);
    return () => {
      window.removeEventListener("scroll", changeNavColor);
    };
  }, []);
  const [isVisible, setIsVisible] = useState(false);

  const toggleVisibility = () => {
    setIsVisible(!isVisible);
  };
  return (
    <div
      id="nav"
      className={`flex align-center fixed w-full z-[55] py-3 top-0 left-0 ${
        dark || navChanger ? "bg-[#041e3f]" : ""
      }`}
    >
      <Container>
        <div className="flex justify-between md:items-center text-white">
          <div>
            <Link to="/">
              <Image src={logo} alt="Tech Nebula" className="h-12 " />
            </Link>
          </div>
          <Navlist dark={dark} />
              <div className="flex md:hidden items-center justify-center">
                <button
                  className=" flex ml-auto  z-10 cursor-pointer text-3xl border border-solid border-transparent rounded bg-transparent md:hidden outline-none focus:outline-none"
                  type="button"
                  onClick={toggleVisibility}
                >
                  <BiMenu />
                </button>
              </div>
        </div>
      </Container>
      <MobileNavlist
      dark={dark}
        isVisible={isVisible}
        toggleVisibility={toggleVisibility}
      />
    </div>
  );
});

export default Navbar;
