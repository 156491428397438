import { ReactComponent as Logo } from "../assets/svg/newLogo.svg";
const Preloader = () => {
  return (
    <div className=" bg-[#041e3f] flex w-full h-screen justify-center fixed items-center z-[100] inset-0">
      <Logo />
    </div>
  );
};

export default Preloader;
