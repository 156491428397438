import Container from "../components/utilities/Container";
import { Link } from "react-router-dom";
import Image from "../components/utilities/Image";
import logo from "../assets/svg/logog.svg";
import { BiMenu } from "react-icons/bi";
import BlogsMainNavlist from "../components/utilities/BlogsMainNavlist";
import { FC, ReactElement, useState } from "react";

const AdminLayout: FC<{ children: ReactElement | ReactElement[] }> = ({
  children,
}) => {
  const [isVisible, setIsVisible] = useState(false);

  const toggleVisibility = () => {
    setIsVisible(!isVisible);
  };
  return (
    <div className="bg-[#e0e9f566] min-h-screen">
      <div className=" bg-white shadow-xl">
        <Container>
          <div className="flex py-4 justify-between md:items-center">
            <div>
              <Link to="/">
                <Image src={logo} alt="Tech Nebula" className="h-12 " />
              </Link>
            </div>
            <div className="flex items-center justify-center">
              <button
                className=" text-black flex ml-auto  z-10 cursor-pointer text-3xl border border-solid border-transparent rounded bg-transparent outline-none focus:outline-none"
                type="button"
                onClick={toggleVisibility}
              >
                <BiMenu />
              </button>
            </div>
          </div>
        </Container>
        <BlogsMainNavlist
          isVisible={isVisible}
          toggleVisibility={toggleVisibility}
        />
      </div>
      <Container className="pt-10">
      {children}
      </Container>
    </div>
  );
};

export default AdminLayout;
