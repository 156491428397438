import { memo } from "react";
import MainBlogsTableCard from "./MainBlogsTableCard";
import { useContext, useEffect } from "react";
import { FirebaseContext } from "../../context/Firebase";
import { Link } from "react-router-dom";
const MainBlogsTable = memo(() => {
  const { BlogData, getBlogData } = useContext(FirebaseContext);
  useEffect(() => {
    getBlogData();
  }, []);

  return (
    <>
      <div className="pb-6">
        <h2 className="text-[#214B4D] text-3xl sm:text-4xl md:text-5xl font-bold text-center ">
          Blogs
        </h2>
      </div>
      <div className="flex justify-end">
        <Link to="add" className="bg-primary text-white font-bold px-3 py-1 rounded hover:bg-[#041e3faa]">
          Add New
        </Link>
      </div>
      <div className="flex flex-col">
        <div className="overflow-x-auto">
          <div className="p-1.5 w-full inline-block align-middle">
            <div className="w-full border rounded-lg">
              <table className="w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      className="px-6 py-3 text-xs font-bold text-left text-gray-500 uppercase "
                    >
                      Title
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-xs font-bold text-left text-gray-500 uppercase "
                    >
                      Slugs
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-xs font-bold text-left text-gray-500 uppercase "
                    >
                      Meta Title
                    </th>
                  </tr>
                </thead>
                {(BlogData || []).map((obj: any) => (
                  <MainBlogsTableCard
                    key={obj.id}
                    Title={obj.Title}
                    Slug={obj.Slug}
                    MetaTitle={obj.MetaTitle}
                  />
                ))}
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
});

export default MainBlogsTable;
