const actions = {
  SIGN_IN_INFO: "SIGN_IN_INFO",
  GET_Info_DATA: "GET_Info_DATA",
  GET_Blog_DATA: "GET_Blog_DATA",
  GET_SINGLE_Blog_DATA:"GET_SINGLE_Blog_DATA",
  SAVE_IN_INFO: "SAVE_IN_INFO",
  SAVE_IN_BLOG: "SAVE_IN_BLOG",
  GET_PROJECTS_DATA: "GET_PROJECTS_DATA",
  GET_Service_DATA: "GET_Service_DATA",
  GET_Gallery_DATA: "GET_Gallery_DATA",
};
export default actions;
