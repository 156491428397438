import React, { useContext, useEffect } from "react";
import { useParams } from "react-router-dom";
import { FirebaseContext } from "../context/Firebase";
import Container from "../components/utilities/Container";
import Image from "../components/utilities/Image";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";

const SingleBlog = () => {
  const { slug } = useParams();
  const { sinleBlogData, getSingleBlogData } = useContext(FirebaseContext);
  useEffect(() => {
    window.scrollTo(0,0)
    if (slug) getSingleBlogData(slug);
  }, [slug]);
  console.log("BlogData", sinleBlogData);

  return (
    <div>
      <Navbar dark />
      <article className="border-b mt-[100px]">
        <Container>
          <>
            {sinleBlogData?.img ? (
              <Image src={sinleBlogData.img} className="w-full mb-10" />
            ) : null}
          </>
          <h3 className="text-3xl font-bold mb-5">{sinleBlogData?.title}</h3>
          <div
            dangerouslySetInnerHTML={{ __html: sinleBlogData?.content || "" }}
          ></div>
          <div className="flex flex-wrap my-5">
            {sinleBlogData?.tags.map((obj) => (
              <span key={obj} className="py-2 rounded-full mr-3 px-4 bg-gray-100">{obj}</span>
            ))}
          </div>
        </Container>
      </article>
      <Footer />
    </div>
  );
};

export default SingleBlog;
