import React, { FC, memo } from "react";

const MainBlogsTableCard: FC<{
  Title: string;
  Slug: string;
  MetaTitle: string;
}> = memo(({ Title, Slug, MetaTitle }) => {
  return (
    <tbody className="divide-y divide-gray-200 bg-gray-100">
      <tr>
        <td className="px-6 py-4 text-sm text-gray-800 whitespace-nowrap">
          {Title}
        </td>
        <td className="px-6 py-4 text-sm text-gray-800 whitespace-nowrap">
          {Slug}
        </td>
        <td className="px-6 py-4 text-sm text-gray-800 whitespace-nowrap">
          {MetaTitle}
        </td>
      </tr>
    </tbody>
  );
});

export default MainBlogsTableCard;
